import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PrayerTable from "./PrayerTable";
import Http from "../helpers/Http";
import { Link } from "gatsby";
import "hijri-date";
import moment from "moment";

const dateFormat = require("dateformat");


const DailyPrayerTimes = () => {
  const [prayerData, setPrayerData] = useState([]);
  const [currentPrayer, setCurrentPrayer] = useState({});
  const [extraPrayer, setExtraPrayer] = useState([]);
  const [eidPrayerTitle, setEidPrayerTitle] = useState(false);
  const [currentTime, setCurrentTime] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");

  const now = new Date();

  const setupCurrentMonth = () => {
    const d = new Date();
    const month = [
      "january", "february", "march", "april", "may", "june", "july", 
      "august", "september", "october", "november", "december"
    ];
    const currentMonth = month[d.getMonth()];
    setCurrentMonth(currentMonth);
  }

  useEffect(() => {
    setupCurrentMonth();
    try {
      Http.get("prayer")
        .then((response) => {
          setPrayerData(response.data.prayerTime);
          setCurrentPrayer(response.data.currentPrayer);
        })
        .catch((error) => {
          console.log(error);
        });
      Http.get("extra")
        .then((response) => {
          setExtraPrayer(response.data);
          response.data.some(prayer => {
            if (prayer.type.name === "EID") {
              setEidPrayerTitle(true);
            }
          })
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  let arrayData = [];

  for (const [key, value] of Object.entries(prayerData)) {
    if (
      key === "fajrJamah" ||
      key === "zuhrJamah" ||
      key === "asrJamah" ||
      key === "maghribJamah" ||
      key === "ishaJamah"
    ) {
      arrayData.push(value);
    }
  }

  // const today = new HijriDate();
  // const day_eid_adha = new HijriDate();
  // const dayGreg = day_eid_adha.toGregorian();

  const convertTime = (time) => {
    return new Date("1970-01-01T" + time + "Z").toLocaleTimeString(
      {},
      {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      }
    );
  };
  // Append any date. Use your birthday.

  function realtime() {
    let time = moment().format("h:mm:ss a");

    // document.getElementById("time").innerHTML = time;
    setCurrentTime(time);

    setInterval(() => {
      time = moment().format("h:mm:ss a");
      // document.getElementById("time").innerHTML = time;
      setCurrentTime(time);

    }, 1000);
  }

  useEffect(() => {
    realtime();
    if (currentTime === convertTime(currentPrayer.time)){
      window.location.reload();
    }
  }, [realtime])

  return (
    <Container>
      <Wrap>
        <Center>
          <Title>Daily Prayer Times</Title>
          <Date1>
            {dateFormat(now, "mmmm dS, yyyy")} {currentTime}
          </Date1>
          <Date2>{new Intl.DateTimeFormat("en-FR-u-ca-islamic", {
      day: "numeric",
      month: "long",
      weekday: "long",
      year: "numeric",
    }).format(Date.now())}</Date2>
          <Text style={{ color: "#ffffff" }}>{currentPrayer.prayerName}</Text>
          <Time>{convertTime(currentPrayer.time)}</Time>
        </Center>
        <PrayerTable prayerData={prayerData} />
        <Center>
          <Title>Jummah Khutbah</Title>
          {extraPrayer.map((item, key) =>
            item.type.name !== "EID" ? (
              <Text key={key}>
                {item.language.name} at {convertTime(item.prayerTime)} by{" "}
                {item.prayerBy}
              </Text>
            ) : null
          )}
          {eidPrayerTitle ? <Title>Eid</Title> : null}
          {extraPrayer.map((item, key) =>
            item.type.name === "EID" ? (
              <Text key={key}>
                {item.language.name} at {convertTime(item.prayerTime)} by{" "}
                {item.prayerBy}
              </Text>
            ) : null
          )}
          <Text>
            Download{" "}
            <a href={`/downloads/${currentMonth}.pdf`} target="_blank" download>
              {currentMonth}
            </a>{" "}
            prayer timetable Download{" "}
          </Text>
        </Center>
      </Wrap>
    </Container>
  );
};;

export default DailyPrayerTimes;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
`;



const Wrap = styled.div`
  max-width: 1024px;
  margin: auto;
`;

const Title = styled.h3`
  font-size: 20px;
  margin: 0;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
`;

const Date1 = styled.span`
  color: #000000;
  font-weight: bold;
`;
const Date2 = styled.span`
  font-size: 14px;
  font-style: italic;
  margin: 5px 0;
`;
const Text = styled.p`
  text-align: center;
  font-size: 14px;
  text-align: center;
  margin: 5px 0;
  font-weight: bold;
`;
const Time = styled.time`
  color: #ffffff;
  font-weight: bold;
`;

const Container = styled.div`
  flex: 1;
  box-shadow: 0 0 2px 1px rgb(0 0 0 / 25%);
  background: #84ba40;
  border-radius: 4px;
  padding: 5px 2%;

  @media (max-width: 480px) {
    ${Title} {
      font-size: 30px;
    }
  }

  @media (max-width: 375px) {
    ${Title} {
      font-size: 25px;
    }
  }
`;